import React from 'react';
import './InfoSection.css';

function InfoSection(props){
    if(props.isMobile){
        return (
            <div className='mobile-section'>
                <div className='mobile-img' 
                    style={{
                        backgroundImage:`url(${props.img})`
                    }}>
                    <div className='text'>
                        <div>
                        <p className='title'>{props.title}</p>
                        {props.text}
                        </div>
                        <div className='direction'>
                            <a
                                rel='noopener noreferrer' 
                                href= {props.url} 
                                target="_blank"
                            >
                                Veibeskrivelse
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <div className='section'>
                <div className='img'>
                    <img 
                        className='render' 
                        src={props.img}
                        alt="declarative"
                    />
                </div>
                <div className='text'>
                    <div>
                        <p className='title'>{props.title}</p>
                        {props.text}
                    </div>
                    <div className='direction'>
                        <a 
                            rel='noopener noreferrer' 
                            href= {props.url} 
                            target="_blank"
                        >
                            Veibeskrivelse
                        </a>
                    </div>
                </div>
            </div>
        )
    }
   
}

export default InfoSection;