import React, {useEffect} from 'react';
import {useOutletContext} from 'react-router-dom'
import InfoSection from '../../Components/InfoSection/InfoSection';
/*import flower from '../../flower_transparent.png'*/
import church from '../../church.jpg'
import hotel from '../../hotel.webp'
import dinner from '../../dinner.jpeg'
import champagne from '../../champagne.jpeg'
/*import power from '../../power.avif'*/
import './Information.css'
import ReactGA from "react-ga4";
import Toastmaster from '../../Components/InfoSection/Toastmaster';
ReactGA.initialize('G-DXMH282P72');

let vielseText = "Vielsen vil være klokken 11:00 i Oppdal Kirke"
let aperitifText = "Det vil bli servert aperitif og fingermat til middagsgjestene 13:30 ved Oppdal Turisthotell"
let middagText = "Middagen vil være klokken 15:00 på Oppdal Turisthotell"
/*let blikjentText = "Dagen før vil det være en 'bli kjent' fest på Festa Kraftverk fra 17:00 til 22:00."*/
let festText = "Alle som er invitert til kaffe avec kan komme til Oppdal Turisthotell klokken 19:00"

const Information = () => {
  let {mobileView} = useOutletContext()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/informasjon", title: "Informasjon" });

  }, []);

  return <div className='info'>
    <div className='preface'>
      {/*<div className='header'>
        GENERELL INFORMASJON        
      </div>*/}
      <div className='toast'> 
        <div className='toastheader'>Toastmastere</div>
        <div className='toastmasters' style={{flexDirection:  mobileView ? "column" : "row", width:mobileView ? "100vw" : "50vw"}}>
          <Toastmaster name="Frederic Bruaset Bræin" mobile="41638743" email="frederic&#8209;95@hotmail.com"/>
          <Toastmaster name="Lena Eriksen Fjellstad" mobile="95414204" email="lenamefjellstad@gmail.com"/>
        </div>
        <div className='content'>Gjerne ta kontakt med Toastmastere hvis du planlegger å ha ett innslag i bryllupet.</div>
      </div>
      <div className='content'>
        <p>Antrekk til festen er dress. </p>
      </div>
    </div>

  <InfoSection 
    title={"Vielsen"} 
    text={vielseText}
    img={church}
    url={"https://www.google.com/maps?sca_esv=563715971&output=search&q=oppdal+kirke"}
    isMobile={mobileView}
  />
  <InfoSection
    title={"Aperitif & fingermat"}
    text={aperitifText} 
    img={champagne}
    url={"https://www.google.com/maps/place/Oppdal+Turisthotell/@62.5930413,9.6902695,17z/data=!3m1!4b1!4m11!3m10!1s0x461353f0e130c15b:0xee647694a4f2faff!5m4!1s2023-12-28!2i3!4m1!1i2!8m2!3d62.5930413!4d9.6928498!16s%2Fg%2F11cnx_t78f?entry=ttu"}
    isMobile={mobileView}
  />
  <InfoSection
    title={"Middagen"}
    text={middagText} 
    img={dinner}
    url={"https://www.google.com/maps/place/Oppdal+Turisthotell/@62.5930413,9.6902695,17z/data=!3m1!4b1!4m11!3m10!1s0x461353f0e130c15b:0xee647694a4f2faff!5m4!1s2023-12-28!2i3!4m1!1i2!8m2!3d62.5930413!4d9.6928498!16s%2Fg%2F11cnx_t78f?entry=ttu"}
    isMobile={mobileView}
  />
  <InfoSection
    title={"Festen"}
    text={festText} 
    img={hotel}
    url={"https://www.google.com/maps/place/Oppdal+Turisthotell/@62.5930413,9.6902695,17z/data=!3m1!4b1!4m11!3m10!1s0x461353f0e130c15b:0xee647694a4f2faff!5m4!1s2023-12-28!2i3!4m1!1i2!8m2!3d62.5930413!4d9.6928498!16s%2Fg%2F11cnx_t78f?entry=ttu"}
    isMobile={mobileView}
  />
</div>;
};

export default Information;