import React, {useEffect, useState} from 'react';
import UploadButton from '../../Components/ImageUpload/UploadButton';
import './Images.css';
import ReactGA from "react-ga4";
import PhotoAlbum from "react-photo-album";
import "react-photo-album/styles.css";


import Box from "@mui/material/Box";
import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";
import allPhotos from "./photos"


ReactGA.initialize('G-DXMH282P72');


const Images = () => {
    let padding = 20
    let spacing = 10
    let width = 90
    let targetRowHeight = 450
    let layout = 'rows'

    const [index, setIndex] = useState(-1);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/bilder", title: "Bilder" });
      }, []);
    

    return <div>
        <Box sx={{ 
            width: `${width}%`, 
            mx: "auto", 
            //backgroundColor: "#9b9a9a"
        }}>
            <PhotoAlbum 
                photos={allPhotos}
                layout={layout}
                spacing={spacing}
                targetRowHeight={targetRowHeight}
                padding={padding}
                onClick={({ index }) => {
                    console.log(index);
                    setIndex(index)}
                }
                render={{
                    button: ({ style, ...rest }) => (
                      <div
                        style={{
                          ...style,
                          borderRadius: padding > 2 ? "4px" : 0,
                          boxShadow:
                            spacing + padding > 0
                              ? "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                              : "none",
                          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        }}
                        {...rest}
                      />
                    ),
                  }}
            />
        </Box>
        <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // CORS Error
                slides={allPhotos.map((slide) => ({
                    ...slide,
                    download: `${slide.src}?download`,
                  }))}
                // enable optional lightbox plugins
                plugins={[Download]}
            />
        <div className='images-container'>
            <p className='content'>Hvis du har tatt noen bilder under bryllupet som du vil dele med oss så kan du laste de opp her</p> 
            <UploadButton/>
        </div>
    </div>;
};

export default Images;