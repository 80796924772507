import React from 'react';
import flower from './flower_transparent.webp'
import splash from './index.jpg'
import {useOutletContext} from 'react-router-dom'
import './App.css';


function App() {
  let {mobileView} = useOutletContext()

  return (
    <div className="App" style={{

    }}>
      <header className="App-header">
        <img className='header-logo' 
          style={{
            borderRadius: "4px",
            width: mobileView ? "10vw" : "",
            height: !mobileView ? "10vh" : "",
          }}
          src={flower} alt="blomst :)"/>
        <p className='greeting'>
          22. Juni 2024
        </p>
        <p></p>
        <div style={{
            padding: "4px"}}>
          <img style={{
            borderRadius: "4px",
            width: mobileView ? "70vw" : "",
            height: !mobileView ? "50vh" : "",
            padding: mobileView ? "3vw": "1vw",
            boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }} 
          className="splash-image" 
          src={splash}  
          alt="Kamilla og OG"
          />
        </div>
      </header>
    </div>
  );
}

export default App;
