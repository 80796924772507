import img1 from "../../gallery/1.JPG"
import img2 from "../../gallery/2.JPG"
import img3 from "../../gallery/3.JPG"
import img4 from "../../gallery/4.JPG"
import img5 from "../../gallery/5.JPG"
import img6 from "../../gallery/6.JPG"
import img7 from "../../gallery/7.JPG"
import img8 from "../../gallery/8.JPG"
import img9 from "../../gallery/9.JPG"
import img10 from "../../gallery/10.JPG"
import img11 from "../../gallery/11.JPG"
import img12 from "../../gallery/12.JPG"
import img13 from "../../gallery/13.JPG"
import img14 from "../../gallery/14.JPG"
import img15 from "../../gallery/15.JPG"
import img16 from "../../gallery/16.JPG"
import img17 from "../../gallery/17.JPG"
import img18 from "../../gallery/18.JPG"
import img19 from "../../gallery/19.JPG"
import img20 from "../../gallery/20.JPG"
import img21 from "../../gallery/21.JPG"
import img22 from "../../gallery/22.JPG"
import img23 from "../../gallery/23.JPG"
import img24 from "../../gallery/24.JPG"
import img25 from "../../gallery/25.JPG"
import img26 from "../../gallery/26.JPG"
import img28 from "../../gallery/28.JPG"
import img29 from "../../gallery/29.JPG"
import img30 from "../../gallery/30.JPG"
import img31 from "../../gallery/31.JPG"
import img32 from "../../gallery/32.JPG"
import img33 from "../../gallery/33.JPG"
import img34 from "../../gallery/34.JPG"
import img35 from "../../gallery/35.JPG"
import img36 from "../../gallery/36.JPG"
import img37 from "../../gallery/37.JPG"
import img38 from "../../gallery/38.JPG"
import img39 from "../../gallery/39.JPG"
import img40 from "../../gallery/40.JPG"
import img41 from "../../gallery/41.JPG"
import img42 from "../../gallery/42.JPG"
import img43 from "../../gallery/43.JPG"
import img44 from "../../gallery/44.JPG"
import img45 from "../../gallery/45.JPG"
import img46 from "../../gallery/46.JPG"
import img47 from "../../gallery/47.JPG"
import img48 from "../../gallery/48.JPG"
import img49 from "../../gallery/49.JPG"
import img50 from "../../gallery/50.JPG"
import img51 from "../../gallery/51.JPG"
import img52 from "../../gallery/52.JPG"
import img53 from "../../gallery/53.JPG"
import img54 from "../../gallery/54.JPG"
import img55 from "../../gallery/55.JPG"
import img56 from "../../gallery/56.JPG"
import img57 from "../../gallery/57.JPG"
import img58 from "../../gallery/58.JPG"
import img59 from "../../gallery/59.JPG"
import img60 from "../../gallery/60.JPG"
import img61 from "../../gallery/61.JPG"
import img62 from "../../gallery/62.JPG"
import img63 from "../../gallery/63.JPG"
import img64 from "../../gallery/64.JPG"
import img65 from "../../gallery/65.JPG"
import img66 from "../../gallery/66.JPG"
import img67 from "../../gallery/67.JPG"
import img68 from "../../gallery/68.JPG"
import img69 from "../../gallery/69.JPG"
import img70 from "../../gallery/70.JPG"
import img71 from "../../gallery/71.JPG"
import img72 from "../../gallery/72.JPG"
import img73 from "../../gallery/73.JPG"
import img74 from "../../gallery/74.JPG"
import img75 from "../../gallery/75.JPG"
import img76 from "../../gallery/76.JPG"
import img77 from "../../gallery/77.JPG"
import img78 from "../../gallery/78.JPG"
import img79 from "../../gallery/79.JPG"
import img80 from "../../gallery/80.JPG"
import img81 from "../../gallery/81.JPG"
import img82 from "../../gallery/82.JPG"
import img83 from "../../gallery/83.JPG"
import img84 from "../../gallery/84.JPG"
import img85 from "../../gallery/85.JPG"
import img86 from "../../gallery/86.JPG"
import img87 from "../../gallery/87.JPG"
import img88 from "../../gallery/88.JPG"
import img89 from "../../gallery/89.JPG"
import img90 from "../../gallery/90.JPG"
import img91 from "../../gallery/91.JPG"
import img92 from "../../gallery/92.JPG"
import img93 from "../../gallery/93.JPG"
import img94 from "../../gallery/94.JPG"
import img95 from "../../gallery/95.JPG"
import img96 from "../../gallery/96.JPG"
import img97 from "../../gallery/97.JPG"
import img98 from "../../gallery/98.JPG"
import img99 from "../../gallery/99.JPG"
import img100 from "../../gallery/100.JPG"
import img101 from "../../gallery/101.JPG"
import img102 from "../../gallery/102.JPG"
import img103 from "../../gallery/103.JPG"
import img104 from "../../gallery/104.JPG"
import img105 from "../../gallery/105.JPG"
import img107 from "../../gallery/107.JPG"
import img108 from "../../gallery/108.JPG"
import img109 from "../../gallery/109.JPG"
import img110 from "../../gallery/110.JPG"
import img111 from "../../gallery/111.JPG"
import img112 from "../../gallery/112.JPG"
import img113 from "../../gallery/113.JPG"
import img114 from "../../gallery/114.JPG"
import img115 from "../../gallery/115.JPG"
import img116 from "../../gallery/116.JPG"
import img117 from "../../gallery/117.JPG"
import img118 from "../../gallery/118.JPG"
import img119 from "../../gallery/119.JPG"
import img120 from "../../gallery/120.JPG"
import img121 from "../../gallery/121.JPG"
import img122 from "../../gallery/122.JPG"
import img123 from "../../gallery/123.JPG"
import img124 from "../../gallery/124.JPG"
import img125 from "../../gallery/125.JPG"
import img126 from "../../gallery/126.JPG"
import img127 from "../../gallery/127.JPG"
import img128 from "../../gallery/128.JPG"
import img129 from "../../gallery/129.JPG"
import img130 from "../../gallery/130.JPG"
import img131 from "../../gallery/131.JPG"
import img132 from "../../gallery/132.JPG"
import img133 from "../../gallery/133.JPG"
import img134 from "../../gallery/134.JPG"
import img135 from "../../gallery/135.JPG"
import img136 from "../../gallery/136.JPG"
import img137 from "../../gallery/137.JPG"
import img138 from "../../gallery/138.JPG"
import img139 from "../../gallery/139.JPG"
import img140 from "../../gallery/140.JPG"
import img141 from "../../gallery/141.JPG"
import img142 from "../../gallery/142.JPG"
import img143 from "../../gallery/143.JPG"
import img144 from "../../gallery/144.JPG"
import img145 from "../../gallery/145.JPG"
import img146 from "../../gallery/146.JPG"
import img147 from "../../gallery/147.JPG"
import img148 from "../../gallery/148.JPG"
import img149 from "../../gallery/149.JPG"
import img150 from "../../gallery/150.JPG"
import img151 from "../../gallery/151.JPG"
import img152 from "../../gallery/152.JPG"
import img153 from "../../gallery/153.JPG"
import img154 from "../../gallery/154.JPG"
import img155 from "../../gallery/155.JPG"
import img156 from "../../gallery/156.JPG"
import img157 from "../../gallery/157.JPG"
import img158 from "../../gallery/158.JPG"
import img159 from "../../gallery/159.JPG"
import img160 from "../../gallery/160.JPG"
import img161 from "../../gallery/161.JPG"
import img162 from "../../gallery/162.JPG"
import img163 from "../../gallery/163.JPG"
import img164 from "../../gallery/164.JPG"

const photos = [
{ src: img1, height: 2751, width: 1830},
{ src: img2, height: 1830, width: 2751},
{ src: img3, height: 1830, width: 2751},
{ src: img4, height: 2751, width: 1830},
{ src: img5, height: 1830, width: 2751},
{ src: img6, height: 1830, width: 2751},
{ src: img7, height: 1830, width: 2751},
{ src: img8, height: 1830, width: 2751},
{ src: img9, height: 1830, width: 2751},
{ src: img10, height: 1830, width: 2751},
{ src: img11, height: 1830, width: 2751},
{ src: img12, height: 1830, width: 2751},
{ src: img13, height: 2751, width: 1830},
{ src: img14, height: 1830, width: 2751},
{ src: img15, height: 2751, width: 1830},
{ src: img16, height: 1830, width: 2751},
{ src: img17, height: 2751, width: 1830},
{ src: img18, height: 1830, width: 2751},
{ src: img19, height: 1830, width: 2751},
{ src: img20, height: 2751, width: 1830},
{ src: img21, height: 1830, width: 2751},
{ src: img22, height: 1830, width: 2751},
{ src: img23, height: 1830, width: 2751},
{ src: img24, height: 2751, width: 1830},
{ src: img25, height: 2751, width: 1830},
{ src: img26, height: 2751, width: 1830},
{ src: img28, height: 1830, width: 2751},
{ src: img29, height: 1830, width: 2751},
{ src: img30, height: 1830, width: 2751},
{ src: img31, height: 1830, width: 2751},
{ src: img32, height: 1830, width: 2751},
{ src: img33, height: 2751, width: 1830},
{ src: img34, height: 2751, width: 1830},
{ src: img35, height: 1830, width: 2751},
{ src: img36, height: 1830, width: 2751},
{ src: img37, height: 1830, width: 2751},
{ src: img38, height: 1830, width: 2751},
{ src: img39, height: 1830, width: 2751},
{ src: img40, height: 1830, width: 2751},
{ src: img41, height: 1830, width: 2751},
{ src: img42, height: 2751, width: 1830},
{ src: img43, height: 1830, width: 2751},
{ src: img44, height: 1830, width: 2751},
{ src: img45, height: 1830, width: 2751},
{ src: img46, height: 1830, width: 2751},
{ src: img47, height: 1830, width: 2751},
{ src: img48, height: 1830, width: 2751},
{ src: img49, height: 1830, width: 2751},
{ src: img50, height: 1830, width: 2751},
{ src: img51, height: 1830, width: 2751},
{ src: img52, height: 1830, width: 2751},
{ src: img53, height: 1830, width: 2751},
{ src: img54, height: 1830, width: 2751},
{ src: img55, height: 1830, width: 2751},
{ src: img56, height: 1830, width: 2751},
{ src: img57, height: 1830, width: 2751},
{ src: img58, height: 1830, width: 2751},
{ src: img59, height: 1830, width: 2751},
{ src: img60, height: 1830, width: 2751},
{ src: img61, height: 1830, width: 2751},
{ src: img62, height: 1830, width: 2751},
{ src: img63, height: 2751, width: 1830},
{ src: img64, height: 2751, width: 1830},
{ src: img65, height: 2751, width: 1830},
{ src: img66, height: 1830, width: 2751},
{ src: img67, height: 1830, width: 2751},
{ src: img68, height: 1830, width: 2751},
{ src: img69, height: 1830, width: 2751},
{ src: img70, height: 1830, width: 2751},
{ src: img71, height: 1830, width: 2751},
{ src: img72, height: 2751, width: 1830},
{ src: img73, height: 1830, width: 2751},
{ src: img74, height: 1830, width: 2751},
{ src: img75, height: 1830, width: 2751},
{ src: img76, height: 1830, width: 2751},
{ src: img77, height: 1830, width: 2751},
{ src: img78, height: 1830, width: 2751},
{ src: img79, height: 2751, width: 1830},
{ src: img80, height: 1830, width: 2751},
{ src: img81, height: 1830, width: 2751},
{ src: img82, height: 1830, width: 2751},
{ src: img83, height: 1830, width: 2751},
{ src: img84, height: 1830, width: 2751},
{ src: img85, height: 1830, width: 2751},
{ src: img86, height: 1830, width: 2751},
{ src: img87, height: 1830, width: 2751},
{ src: img88, height: 1830, width: 2751},
{ src: img89, height: 1830, width: 2751},
{ src: img90, height: 1830, width: 2751},
{ src: img91, height: 1830, width: 2751},
{ src: img92, height: 2751, width: 1830},
{ src: img93, height: 1830, width: 2751},
{ src: img94, height: 1830, width: 2751},
{ src: img95, height: 1830, width: 2751},
{ src: img96, height: 1830, width: 2751},
{ src: img97, height: 1830, width: 2751},
{ src: img98, height: 1830, width: 2751},
{ src: img99, height: 1830, width: 2751},
{ src: img100, height: 1830, width: 2751},
{ src: img101, height: 1830, width: 2751},
{ src: img102, height: 1830, width: 2751},
{ src: img103, height: 1830, width: 2751},
{ src: img104, height: 2751, width: 1830},
{ src: img105, height: 1830, width: 2751},
{ src: img107, height: 1830, width: 2751},
{ src: img108, height: 1830, width: 2751},
{ src: img109, height: 1830, width: 2751},
{ src: img110, height: 1830, width: 2751},
{ src: img111, height: 2751, width: 1830},
{ src: img112, height: 1830, width: 2751},
{ src: img113, height: 1830, width: 2751},
{ src: img114, height: 2751, width: 1830},
{ src: img115, height: 1830, width: 2751},
{ src: img116, height: 1830, width: 2751},
{ src: img117, height: 1830, width: 2751},
{ src: img118, height: 1830, width: 2751},
{ src: img119, height: 1830, width: 2751},
{ src: img120, height: 1830, width: 2751},
{ src: img121, height: 2751, width: 1830},
{ src: img122, height: 2751, width: 1830},
{ src: img123, height: 2751, width: 1830},
{ src: img124, height: 2751, width: 1830},
{ src: img125, height: 2751, width: 1830},
{ src: img126, height: 2751, width: 1830},
{ src: img127, height: 1830, width: 2751},
{ src: img128, height: 2751, width: 1830},
{ src: img129, height: 1830, width: 2751},
{ src: img130, height: 2751, width: 1830},
{ src: img131, height: 2751, width: 1830},
{ src: img132, height: 1830, width: 2751},
{ src: img133, height: 2751, width: 1830},
{ src: img134, height: 1830, width: 2751},
{ src: img135, height: 1830, width: 2751},
{ src: img136, height: 1830, width: 2751},
{ src: img137, height: 1830, width: 2751},
{ src: img138, height: 1830, width: 2751},
{ src: img139, height: 1830, width: 2751},
{ src: img140, height: 2751, width: 1830},
{ src: img141, height: 1830, width: 2751},
{ src: img142, height: 1830, width: 2751},
{ src: img143, height: 1830, width: 2751},
{ src: img144, height: 1830, width: 2751},
{ src: img145, height: 1830, width: 2751},
{ src: img146, height: 1830, width: 2751},
{ src: img147, height: 1830, width: 2751},
{ src: img148, height: 1830, width: 2751},
{ src: img149, height: 1830, width: 2751},
{ src: img150, height: 1830, width: 2751},
{ src: img151, height: 2751, width: 1830},
{ src: img152, height: 1830, width: 2751},
{ src: img153, height: 1830, width: 2751},
{ src: img154, height: 1830, width: 2751},
{ src: img155, height: 2751, width: 1830},
{ src: img156, height: 1830, width: 2751},
{ src: img157, height: 1830, width: 2751},
{ src: img158, height: 1830, width: 2751},
{ src: img159, height: 1830, width: 2751},
{ src: img160, height: 1830, width: 2751},
{ src: img161, height: 1830, width: 2751},
{ src: img162, height: 1830, width: 2751},
{ src: img163, height: 1830, width: 2751},
{ src: img164, height: 1830, width: 2751},
];

export default photos