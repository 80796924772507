import React, {useEffect} from 'react';
import './Menu.css'
import ReactGA from "react-ga4";
ReactGA.initialize('G-DXMH282P72');


const Menu = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/meny", title: "Meny" });
    
      }, []);
    return <div className='menu-container'> 
    <div className='header'>
      Meny
    </div>
    <div className='entry'>
      <div className='menu-entry-header'>
        Forrett
      </div>
      <div className='menu-entry-main'>Kamskjell fra Hitra</div>
      <div className='menu-entry-description'>
        Beurre blanc, hummerolje, saltbakt sellerirot og mikrogrønt fra Aalbu Markedshage
      </div>
      <div className='menu-entry-allergies'>(sj, m, su)</div>
    </div>
    <div className='entry'>
      <div className='menu-entry-header'>
        Hovedrett
      </div>
      <div className='menu-entry-main'>Flatbiff av kronhjort</div>
      <div className='menu-entry-description'>
        Asparges, vårløk fra Aalbu, brokkolini, blomkålpuré, xérès sherrysjy og potetpuré av Ratte Peat
      </div>
      <div className='menu-entry-allergies'>(m, su)</div>
      <hr/>
      <div className='menu-entry-main'>Torskerygg fra Uthaug</div>
      <div className='menu-entry-description'>
      Asparges, vårløk fra Aalbu, brokkolini, blomkålpuré, smørsaus, ramsløksolje, gressløk og potetpuré av Ratte Peat
      </div>
      <div className='menu-entry-allergies'>(f, m, su)</div>
    </div>
    <div className='entry'>
      <div className='menu-entry-header'>
        Dessert
      </div>
      <div className='menu-entry-main'>Kamillas Sommerdrøm</div>
      <div className='menu-entry-description'>
        Bringebærsorbet, sitroncurd, vaniljebavarois, norske jordbær, blåbær, ristet havrecrunch, oxalis og kjørvel 
      </div>
      <div className='menu-entry-allergies'>(e, m, su, h)</div>
    </div>
    <div className='allergy-explanation'>Allergener: sj- sjømat, m- melk, su- sulfit, f- fisk, e- egg, h- hvete</div>
    </div>;
};

export default Menu;