import React from 'react';
import './Toastmaster.css';


function Toastmaster(props){

    return (
        <div className='toast-section'>
            <div className='name'> {props.name}</div>
            <div className='info'> 
                <div> Mobil: {props.mobile}</div>
                <div> Epost: {props.email}</div>
            </div>
        </div>
    )   
}

export default Toastmaster;