import React, { useState } from 'react';
import './Burger.css';

function Burger(props) {
    // do not need visible menu variable, but helps understanding the logic
    const [isVisibleMenu, setVisibleMenu] = useState(false);
    const [menuListStyle, setMenuListStyle] = useState({ display: "none", opacity: "0.0" });
    const [buttonState, setButtonState] = useState("button-state-bars")

    const closeMenu = () => {
        setVisibleMenu(false)
        setMenuListStyle({ display: "none", opacity: "0" });
        setButtonState("button-state-bars");
        document.body.style.overflow = "unset"
    }

    const handleMenuClick = () => {
        setVisibleMenu(!isVisibleMenu)
        if (isVisibleMenu) {
            setMenuListStyle({ display: "none", opacity: "0" });
            setButtonState("button-state-bars");
            document.body.style.overflow = "unset"
        } else {
            setMenuListStyle({ display: "flex", opacity: "0.9" });
            setButtonState("button-state-times");
            document.body.style.overflow = "hidden"
        }
    }

    return (
        <div className="burger-menu-base-layer">
            <div className="burger-menu-wrapper">
                <div className="menu-button">
                    <button className={buttonState} onClick={handleMenuClick}>
                        <svg viewBox="0 0 100 100">
                            <rect className="svg-burger-top" x="0" y="40" width="100" height="4"></rect>
                            <rect className="svg-burger-bottom" x="0" y="60" width="100" height="4"></rect>
                        </svg></button>
                </div>
            </div>
            <div style={{ 
                opacity: menuListStyle.opacity, 
                display: menuListStyle.display,
                }} 
                className="menu-item-list">
                <div style={{
                    opacity: menuListStyle.opacity 
                    }} 
                    className="menu-item">
                    {props.children.map((child, index) =>
                        <React.Fragment key={index}>
                           {React.cloneElement(child, {closeMenu: closeMenu})}
                        </React.Fragment>)}
                </div>
            </div>
        </div>
    )
}

Burger.displayName = "Burger";

export default Burger;