import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import "./index.css";
import NavBar from './Components/NavBar/NavBar';
import App from './App';
import Information from './routes/Information/Information'
import Menu from './routes/Menu/Menu';
import Images from './routes/Images/Images';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './error-page';
import * as rdd from 'react-device-detect'
import ReactGA from "react-ga4";
import useScreenSize from './utils/useScreenSize';
import Footer from './Components/Footer/Footer';

ReactGA.initialize('G-DXMH282P72');


const NavbarWrapper = () => {
  const screenSize = useScreenSize();
  let mobileView = false
  if(screenSize.width < 700 || (rdd.isMobile && !rdd.isTablet)){
    mobileView = true
  }else{
    mobileView = false
  }
  if(screenSize.width > 700){
    mobileView = false
  }
  return (
  <div className='wrapper'>
      <NavBar isMobile={mobileView}/>
      <Outlet context={{mobileView}}/>
      <Footer/>
  </div>
  )
};

const router = createBrowserRouter([
  {
    path: "/", 
    element: <NavbarWrapper/>,
    errorElement: <ErrorPage />,
    children:[
      {
        path: "/", // yes, again
        element: <App />
      },
      /*{
        path: "/rsvp",
        element: <RSVP />
      },*/
      {
        path: "/informasjon",
        element: <Information />
      },
      {
        path: "/meny",
        element: <Menu />
      },
      /*{
        path: "/onskeliste",
        element: <Wishlist />
      },*/
      {
        path: "/bilder",
        element: <Images />
      },
    ]
  }
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div>
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
