import React from 'react';
import './Footer.css'


const Footer = () => {
    return (
        <div className='footer'>
            <div>
                Kamilla: 45419101 / Odd Gunnar: 93672591 
            </div>
        </div>
    );
};

export default Footer;





