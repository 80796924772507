import React from 'react'
import { NavLink } from 'react-router-dom';
import './MenuItem.css';


function MenuItem(props) {
  
  return (
    <NavLink 
      to={`/${props.title.toLowerCase().replace("ø", "o")}`}
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
      onClick={props.closeMenu}
    >
      <span className="menu-title">{props.title}</span>
    </NavLink>
  );
}

MenuItem.displayName = 'MenuItem';

export default MenuItem;
