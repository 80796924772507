import React, { useRef, useState } from "react";
import "./UploadButton.css"
import getFirebase from "../../firebase";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import MyCustomButton from "./MyCustomButton";

const UploadButton = (props) => {
    const generateRandomHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    let prefix = generateRandomHex(12)
    // Check for cookie fullname in cookies
    let fullname = document.cookie.split('; ').filter(row => row.startsWith('fullname=')).map(c=>c.split('=')[1])[0]
    if(fullname !== undefined){
        prefix = fullname
    }else{
        let previous_hex = document.cookie.split('; ').filter(row => row.startsWith('prevhex=')).map(c=>c.split('=')[1])[0]
        if(previous_hex !== undefined){
            prefix = previous_hex
        }else{
            document.cookie = `prevhex=${prefix}`
        }
    }
        

    const [progress, setProgress] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [fileUploaded, setFileUploaded] = useState(false)
    const [uploadError, setUploadError] = useState(false)

    const update_progress_bar = (current_progress) => {
        if(current_progress > progress){
            setProgress(current_progress)
        }
    }

    const firebase = getFirebase();
    const reference = useRef(undefined);

    const handleClick = () => {
        if (reference) {
            return reference.current?.click();
        }
    };

    const handleUpload = async (event) => {
        setFileUploaded(false)
        if (!firebase) return;

        const uploadedFile = event?.target.files[0];
        if (!uploadedFile) return;

        // Get a reference to the storage service, which is used to create references in your storage bucket
        const storage = getStorage(firebase,'gs://kamillaogoddgunnar.appspot.com');

        // Create a storage reference from our storage service
        const storageRef = ref(storage, 'images/'+ prefix + '/' + uploadedFile.name);
        const uploadTask = uploadBytesResumable(storageRef, uploadedFile);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            update_progress_bar((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    setUploading(true)
                    console.log('Upload is running');
                    break;
                default:
                    break;
            }
        }, 
        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    setUploadError(true)
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    setUploadError(true)
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    setUploadError(true)
                    break;
                default:
                    setUploadError(true)
                    break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            //getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //console.log('File available at', downloadURL);
            setFileUploaded(true)
            update_progress_bar(0);
            setUploading(false)
            //});
        });
    };

    return (
        <div className="upload-button-container">
          <MyCustomButton onClick={() => handleClick()} />
          <input
            type="file"
            ref={reference}
            accept=".png, .jpg, .jpeg"
            hidden
            onChange={handleUpload}
          />
          {(progress > 0 && uploading) && (
            <div className="progress-bar">Laster opp: {progress} %</div>
          )}
          {fileUploaded && (
            <div className="progress-bar">Opplasting fullført! Takk for at du delte bilder med oss</div>
          )}
            {uploadError && (
            <div className="progress-bar">
                <p>Det skjedde en feil hos oss : ( </p>
                <p>Du kan fortsatt sende bilder på epost:</p>
                <p>odd.gunnar.aspaas@gmail.com</p>
                <p>kamilla_lonset@hotmail.com</p>
            </div>
          )}
        </div>
    );
};

export default UploadButton;
