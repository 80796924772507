import React from 'react'
import Burger from './Burger/Burger';
import DesktopNavbar from './DesktopNavbar/DesktopNavbar'
import "./NavMenuHandler.css"


function NavMenuHandler(props) {
    if (props.isMobile) {
        return <div className="burger-container">
            <Burger>
                {props.children}
            </Burger>
        </div>
    } else {
        return <div className='navbar-container'>
            <DesktopNavbar>
                {props.children}
            </DesktopNavbar>
        </div>
    }
}

export default NavMenuHandler;